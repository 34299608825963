import React, { useState, memo } from 'react';

import styles from './styles.module.scss';

const Input = ({
  label,
  placeholder,
  type,
  error,
  value,
  name,
  icon,
  onChange,
  onIconClick,
  required,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  // const [readonly, setReadonly] = useState(true);
  const handleFocus = () => {
    setIsFocused(true);
    // setReadonly(false);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleIconClick = () => {
    if (onIconClick) {
      if (name) {
        onIconClick(name);
      } else {
        onIconClick();
      }
    }
  };
  const randomNum = Math.random(100);
  return (
    <div className={styles.container}>
      <label
        htmlFor={`${name}${randomNum}`}
        className={[
          styles.label,
          isFocused || value ? styles.label_focused : '',
        ].join(' ')}
        data-test="label"
      >
        {label}
        {required && <span className={styles.required}> *</span>}
      </label>
      <div
        className={[
          styles.inputContainer,
          isFocused && !error ? styles.inputContainer__focused : '',
          error ? styles.inputContainer__error : '',
          value && !error ? styles.inputContainer__success : '',
          type === 'textarea' ? styles.textareaContainer : '',
        ].join(' ')}
      >
        <input
          id={`${name}${randomNum}`}
          className={styles.input}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          name={name}
          type={type}
          placeholder={placeholder}
          autoComplete="new-password"
          // readOnly={readonly}
        />
        {icon &&
          (onIconClick ? (
            <button
              type="button"
              className={styles.iconContainer}
              onClick={handleIconClick}
              aria-label="Icon"
            >
              {icon}
            </button>
          ) : (
            <div className={styles.iconContainer}>{icon}</div>
          ))}
        {error && <div className={styles.errorContainer}>{error}</div>}
      </div>
    </div>
  );
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  handleChange: () => {},
  type: 'text',
};

export default memo(Input);
