import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';

import { DataLayerContext } from 'providers/DataLayer';

import styles from './styles.module.scss';

const Warning = ({ error, onClose, username }) => {
  const { t } = useTranslation();
  const {
    client: { primary_color },
  } = useContext(DataLayerContext);

  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     if (error) {
  //       onClose();
  //     }
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // });

  let errorText = '';

  if (error.data && error.data.message) {
    errorText = error.data.message;
  } else if (typeof error.message === 'string') {
    errorText =
      error.message === 'Network Error' && username
        ? t('No connect', { name: username })
        : t('Oops, something wrong.');
  } else {
    errorText = t('Something went wrong');
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_inner}>
        <div className={styles.header} data-test="error">
          {errorText}
        </div>
        <p className={styles.text}>{t('Please try again later.')}</p>
        <button
          type="button"
          onClick={onClose}
          className={styles.button}
          style={primary_color ? { backgroundColor: primary_color } : {}}
        >
          {t('Close')}
        </button>
      </div>
    </div>
  );
};

export default Warning;
