import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { isBC, isCalls } from 'utils/ident';

import Error404 from 'components/Error404';
import { Routes } from 'consts';

const MainPage = lazy(() => import('pages/MainPage'));
const PickTimePage = lazy(() => import('pages/PickTimePage'));
const ConfirmPage = lazy(() => import('pages/ConfirmPage'));
const CardPage = lazy(() => import('pages/CardPage'));
const IdentPage = lazy(() => import('pages/IdentPage'));

/**
 * Router component
 * ================
 * Configures client-side routing
 * @param {Object} layout props from Layout provider
 * @param {Object} data props from DataLayer provider
 */

const Router = ({ layout, data }) => {
  return (
    <BrowserRouter>
      <Switch>
        {isCalls && (
          <Route
            exact
            path={Routes.ASSISTANT_MAIN}
            render={() => (
              <Suspense fallback="">
                <MainPage layout={layout} data={data} />
              </Suspense>
            )}
          />
        )}
        {isCalls && (
          <Route
            exact
            path={Routes.ASSISTANT_PICK}
            render={() => (
              <Suspense fallback="">
                <PickTimePage layout={layout} data={data} />
              </Suspense>
            )}
          />
        )}
        {isCalls && (
          <Route
            exact
            path={Routes.ASSISTANT_IDENT}
            render={() => (
              <Suspense fallback="">
                <IdentPage />
              </Suspense>
            )}
          />
        )}
        {isCalls && (
          <Route
            exact
            path={Routes.ASSISTANT_CONFIRM}
            render={() => (
              <Suspense fallback="">
                <ConfirmPage layout={layout} data={data} />
              </Suspense>
            )}
          />
        )}
        {isBC && (
          <Route
            exact
            path={Routes.BC_MAIN}
            render={() => (
              <Suspense fallback="">
                <MainPage layout={layout} data={data} isBC />
              </Suspense>
            )}
          />
        )}
        {isBC && (
          <Route
            exact
            path={Routes.BC_PICK}
            render={() => (
              <Suspense fallback="">
                <PickTimePage layout={layout} data={data} isBC />
              </Suspense>
            )}
          />
        )}
        {isBC && (
          <Route
            exact
            path={Routes.BC_IDENT}
            render={() => (
              <Suspense fallback="">
                <IdentPage />
              </Suspense>
            )}
          />
        )}
        {isBC && (
          <Route
            exact
            path={Routes.BC_CONFIRM}
            render={() => (
              <Suspense fallback="">
                <ConfirmPage layout={layout} data={data} isBC />
              </Suspense>
            )}
          />
        )}
        {isBC && (
          <Route
            exact
            path={Routes.BC_CARD}
            render={() => (
              <Suspense fallback="">
                <CardPage layout={layout} data={data} isBC />
              </Suspense>
            )}
          />
        )}
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
