import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

import api from '../services/api';

export const DataLayerContext = createContext({});

class DataLayerProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      selectedTime: '',
      ownerTimeOffset: null,
      ownTime: '',
      card: {
        slug: '',
        full_name: '',
        primary_color: '',
        bg_image: '',
        address: '',
        phones: [],
        email: '',
        logo: '',
        job_title: '',
        business_name: '',
        business_description: '',
        opening_hours: [],
        social_profiles: [],
        website: '',
        mode: 'published',
        user_type: '',
      },
      messageSent: false,
      errorMessage: '',
      errorGetCard: '',
      errorSetClient: '',
      timeSendError: '',
      loadingGetCard: false,
      timeSend: false,
      primary_color: '',
      dataToBack: null,
      isToday: true,
      is24: true,
      isBC: false,
    };
  }

  setClient = (callbackID, isBC) => {
    this.setState({
      errorSetClient: '',
    });
    try {
      api
        .getUserInfo({
          callback_id: callbackID,
          isBusinessCard: isBC,
        })
        .then(res => {
          if (res.status === 200) {
            this.setState({
              client: res.data && res.data.data,
              ownerTimeOffset: res.data.data.utc_offset,
              isBC,
              // isBC: res.data && res.data.data && res.data.data.business_name,
              // ownerTimeOffset: 0,
            });
            // window.localStorage.removeItem('source');
            document.title = `Calls.Ai - ${res.data.data.full_name ||
              'Call Assistant'}`;
          }
        })
        .catch(error => {
          this.setState({
            errorSetClient: error,
          });
          console.error(error);
        });
    } catch (e) {
      this.setState({
        errorSetClient: e,
      });
      console.error(e);
    }
  };

  getBusinessCard = callbackID => {
    this.setState({
      loadingGetCard: true,
      errorGetCard: '',
    });
    try {
      api
        .getBusinessCard({
          callback_id: callbackID,
        })
        .then(res => {
          if (res.status === 200 && res.data) {
            this.setState({
              card: res.data,
              loadingGetCard: false,
            });
            res.data.primary_color &&
              this.setState({
                primary_color: res.data.primary_color,
                // primary_color: '#dc1c2d',
              });
          }
        })
        .catch(error => {
          this.setState({
            errorGetCard: error,
            loadingGetCard: false,
          });
          console.error(error);
        });
    } catch (e) {
      this.setState({
        errorGetCard: e,
        loadingGetCard: false,
      });
      console.error(e);
    }
  };

  putMessage = data => {
    try {
      api
        .putMessage(data)
        .then(res => {
          if (res.status > 200 && res.status < 304) {
            this.setState({
              messageSent: true,
              errorMessage: '',
            });
          }
        })
        .catch(error => {
          this.setState({
            errorMessage: error,
          });
          console.error(error);
        });
    } catch (e) {
      this.setState({
        errorMessage: e,
      });
      console.error(e);
    }
  };

  sendTime = data => {
    let dataToBack = {
      callback_id: data.callback_id,
      body: {
        ...data.body,
        name: data.body?.name || '',
        phone: data.body?.phone || '',
      },
    };
    if (this.state.isBC) {
      dataToBack = {
        callback_id: this.state.dataToBack.callback_id,
        body: {
          ...this.state.dataToBack.body,
          name: data.name,
          phone: data.phone,
        },
        isBusinessCard: !!this.state.isBC,
      };
    }
    try {
      api
        .setUserInfo(dataToBack)
        .then(res => {
          if (res.status > 200 && res.status < 304) {
            this.setState({
              timeSend: true,
            });
          } else {
            this.setState({
              timeSendError: true,
            });
          }
        })
        .catch(err => {
          if (err.response) {
            this.setState({
              timeSendError: err.response,
            });
          } else {
            this.setState({
              timeSendError: err,
            });
          }
          console.error(err);
        });
    } catch (e) {
      this.setState({
        errorMessage: e,
      });
      console.error(e);
    }
  };

  clearTimeSendError = () => {
    this.setState({
      timeSendError: '',
    });
  };

  setDataToBack = data => {
    this.setState({
      dataToBack: data,
    });
  };

  setIsToday = data => {
    this.setState({
      isToday: data,
    });
  };

  set24Format = data => {
    this.setState({
      is24: data,
    });
  };

  setMessageSent = value => {
    this.setState({
      messageSent: value,
    });
  };

  clearError = () => {
    this.setState({
      errorMessage: '',
    });
  };

  setClientSelectedTime = (selectedTime, day) => {
    let time = selectedTime;
    day === 'today' && (time = `${day} ${selectedTime}`);
    day === 'tomorrow' && (time = `${day} ${selectedTime}`);
    this.setState({ selectedTime: time });
  };

  setOwnTime = ownTime => {
    this.setState({ ownTime });
  };

  setOwnTimeOffset = timeOffset => {
    this.setState({ ownerTimeOffset: timeOffset });
  };

  render() {
    const { children } = this.props;
    return (
      <DataLayerContext.Provider
        value={{
          ...this.state,
          setClientSelectedTime: this.setClientSelectedTime,
          setClient: this.setClient,
          putMessage: this.putMessage,
          getBusinessCard: this.getBusinessCard,
          setOwnTime: this.setOwnTime,
          setOwnTimeOffset: this.setOwnTimeOffset,
          setMessageSent: this.setMessageSent,
          setDataToBack: this.setDataToBack,
          sendTime: this.sendTime,
          clearTimeSendError: this.clearTimeSendError,
          clearError: this.clearError,
          setIsToday: this.setIsToday,
          set24Format: this.set24Format,
        }}
      >
        {children}
      </DataLayerContext.Provider>
    );
  }
}

DataLayerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataLayerProvider;
