const {
  REACT_APP_BASE_PROD_CARD_URL,
  REACT_APP_BASE_STAGE_CARD_URL,
  REACT_APP_BASE_PROD_CALLS_URL,
  REACT_APP_BASE_STAGE_CALLS_URL,
} = process.env;

export const isCalls =
  window.location.href.includes('localhost:3000/assistant') ||
  window.location.href.startsWith(
    `${REACT_APP_BASE_PROD_CALLS_URL}/assistant`,
  ) ||
  window.location.href.startsWith(
    `${REACT_APP_BASE_STAGE_CALLS_URL}/assistant`,
  );

export const isBC =
  window.location.href.includes('localhost:3000/bc') ||
  window.location.href.startsWith(REACT_APP_BASE_PROD_CARD_URL) ||
  window.location.href.startsWith(REACT_APP_BASE_STAGE_CARD_URL);

export const MAIN_COLOR = '#05a1fc';
