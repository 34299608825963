import Axios from 'axios';

// const dev = 'stage.api.sync.ai';
// const prod = 'api.sync.ai';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_CARD_URL = process.env.REACT_APP_BASE_CARD_API_URL;
// const BASE_URL = `https://${dev}/api/v1/assistant/calls/callback`;

const $http = Axios.create({
  headers: {
    'X-Syncai-App-Id': 'ai.sync.call',
    'X-Syncai-App-Version': '1.0',
    'X-Syncai-App-Uuid': '287fe08d-3c72-4f40-b71f-e21d03ae644a',
  },
});

export default {
  setUserInfo: data =>
    $http.put(
      `${BASE_URL}${data.callback_id}${
        data.isBusinessCard ? '?source=bc' : ''
      }`,
      data.body,
    ),

  getUserInfo: ({ callback_id, isBusinessCard }) =>
    $http.get(`${BASE_URL}${callback_id}${isBusinessCard ? '?source=bc' : ''}`),

  getBusinessCard: data => $http.get(`${BASE_CARD_URL}${data.callback_id}`),

  putMessage: ({ slug, body }) =>
    $http.put(`${BASE_CARD_URL}${slug}/message`, body),
};
