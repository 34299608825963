import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import styles from './styles.module.scss';

const Header = ({ children, className }) => (
  <header className={clsx(styles.container, className)}>{children}</header>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Header;
