export const Routes = {
  ASSISTANT_MAIN: '/assistant/:callbackID',
  ASSISTANT_PICK: '/assistant/:callbackID/pick',
  ASSISTANT_IDENT: '/assistant/:callbackID/ident',
  ASSISTANT_CONFIRM: '/assistant/:callbackID/confirm',
  BC_MAIN: '/bc/:callbackID/bc',
  BC_PICK: '/bc/:callbackID/bc/pick',
  BC_IDENT: '/bc/:callbackID/bc/ident',
  BC_CONFIRM: '/bc/:callbackID/bc/confirm',
  BC_CARD: '/bc/:cardID',
};
