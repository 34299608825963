import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Button = ({
  type,
  children,
  onClick,
  size,
  color,
  fontColor,
  bgColor,
  primaryColor,
  fontSize,
  textLeft,
  radiusBig,
  heightMid,
  disabled,
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={[
        styles.container,
        textLeft ? styles.container_textLeft : '',
        radiusBig ? styles.container_radiusBig : '',
        heightMid ? styles.container_heightMid : '',
        styles[size ? `button_${size}` : ''],
        styles[
          primaryColor
            ? `button_${primaryColor}`
            : color
            ? `button_${color}`
            : ''
        ],
        styles[fontSize ? `button_${fontSize}` : ''],
      ].join(' ')}
      onClick={onClick}
      disabled={disabled}
      style={{
        color: fontColor ? fontColor : '',
        backgroundColor: bgColor ? bgColor : '',
        borderColor: bgColor ? bgColor : '',
      }}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'card-sm', 'card-md', 'card-lg']),
  color: PropTypes.oneOf([
    'primary',
    'light',
    'white',
    'whiteActive',
    'whiteDisabled',
  ]),
  fontSize: PropTypes.oneOf(['fontSm', 'fontMd', 'fontLg']),
  primaryColor: PropTypes.string,
  textLeft: PropTypes.bool.isRequired,
  radiusBig: PropTypes.bool.isRequired,
  heightMid: PropTypes.bool.isRequired,
};

Button.defaultProps = {
  type: 'button',
  size: 'lg',
  color: 'primary',
  onClick: null,
  fontSize: 'fontMd',
  textLeft: false,
  radiusBig: false,
  heightMid: false,
};

export default Button;
